const coreUtils = {
  googleAnalytics: function (params) {
    if(window.ga !== 'undefined' && window.ga.getAll() !== 'undefined') {
      var trackerName = window.ga.getAll()[0].get('name');
      window.ga(trackerName + '.send', 'event', params);
    } else {
      console.log('got here');
      setTimeout(this.googleAnalytics(params), 500);
    }
  }
}

export default coreUtils
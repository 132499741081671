// scroll body to 0px on click
$('.get-quotes-link').click(function () {
  scrollToJobForm()
});

$(function () {
  var urlParams = new URLSearchParams(window.location.search);
  if(urlParams.has('get_quotes')) {
    scrollToJobForm()
  }
});

function scrollToJobForm() {
  form = $(".site_form");

  if(form.length > 0) {
    $('body,html').animate({
      scrollTop: form.offset().top
    }, 400);
  }
}
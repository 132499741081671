import 'easy-autocomplete'
import 'easy-autocomplete/src/sass/easy-autocomplete.scss'
import 'easy-autocomplete/src/sass/easy-autocomplete.themes.scss'

$(function () {
  var jobFormWrapper = $('#jobFormWrapper, #businessFormWrapper');
  var textBox = jobFormWrapper.find("#job_suburb_name, #business_suburb_name");
  var fieldID = jobFormWrapper.find("#job_suburb_id, #business_suburb_id");

  var options = {
    url: function(query) {
      return "suburbs?query=" + query + "&format=json";
    },
    getValue: "name",
    list: {
      match: {
        enabled: false
      },
      onClickEvent: function() {
        var value = textBox.getSelectedItemData().id;

        fieldID.val(value).trigger("change");
      }
    },
    minCharNumber: 3,
    theme: "bootstrap"
  };

  textBox.easyAutocomplete(options);
})
import 'bootstrap-datepicker/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min'

$(function () {
  $("input[name='job[starting]']").on('click', function(event) {
    var scheduleDateWrapper = $('#scheduleDateWrapper');
    var scheduleDateInput   = scheduleDateWrapper.find('#job_schedule');

    if(event.currentTarget.id == 'job_starting_on_a_particular_date') {
      scheduleDateWrapper.show();
      scheduleDateInput.prop('disabled', false);
    } else {
      scheduleDateWrapper.hide();
      scheduleDateInput.prop('disabled', true);
    }
  });

  $('#job_schedule').datepicker({
    format: "dd/mm/yyyy",
    startDate: new Date()
  });
})
import coreUtils from 'common/src/lib/analytics'

$(function () {
  var url = window.location.href

  if($('#job_thank_you').length > 0 && url.includes('subcategory_id') && url.includes('suburb')) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'custom.event.submitJob' });

    coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobpostpending' })

    window.history.replaceState({}, document.title, '/job/' + 'thank_you');
  }
})